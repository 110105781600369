@import "../resources/css/Variables"

body a 
    color: $primary
    transition: 500ms
    &:hover
        color: $primary-light
        transition: 500ms

#content-wrapper 
    padding-top: 0
    flex: 1 0 auto

.content-sec .container-lg, .content-sec .container-fluid 
    padding-right: var(--bs-gutter-x,1rem)
    padding-left: var(--bs-gutter-x,1rem)
    padding-top: 1rem
    padding-bottom: 0.5rem

.txt-only .content-sec 
    padding-top: 3vh
    padding-bottom: 2vh
    h1
        font-size: 2rem
    h2
        font-size: 1.5rem
    h3
        font-size: 1.25rem
    section
        margin-bottom: 2rem

.app-start-links
    display: flex
    flex-direction: row
    flex-wrap: wrap
    align-items: center
    justify-content: center
    margin-bottom: 2rem

.txt-only.contentview-wrapper .content-top-wrapper 
    padding-bottom: 0.25rem
    background: $primary

.txt-only.contentview-wrapper .content-top-wrapper .container-lg 
    // padding: 1rem 0 0.5rem 0
    padding-top: 1rem
    padding-bottom: 0.5rem

.flex-container 
    display: flex
    flex-direction: row
    flex-wrap: wrap

.contentview-wrapper .content-top-wrapper h1.content-top-heading 
    width: 100%
    color: $white
    // padding: 1rem
    margin: 0

// .content-sec.detail-txt
//     h1.section_title, h2.section_title
//         display: none
    
// 404 page

#error-page
    padding: 2rem 1rem
    display: flex
    flex-direction: column

// literatur page

#literatur h2 
    font-size: 1.5rem
    margin-top: 1rem

#literatur section 
    padding: 2rem 0
    border-bottom: 1px solid $primary

#literatur section span 
    font-weight: bold

#literatur section h2 
    font-size: 1.5rem
    margin: 0.5rem 0

#literatur section h3 
    font-size: 1.2rem
    margin: 0.5rem 0

#literatur a.btn,
.impressum a.btn 
    background-color: $primary-light
    color: $white
    margin: 0.5rem 0.25rem
    transition: 500ms

#literatur a.btn:hover,
.impressum a.btn:hover 
    background-color: $primary
    transition: 500ms

#literatur section.flex-container div:first-child 
    margin-right: 1rem

#literatur img 
    border: 1px solid $black

// Datenschutz

#datenschutz section,
.barrierefreiheit section 
    margin-bottom: 2rem

#datenschutz h2,
.barrierefreiheit h2 
    margin-bottom: 1rem

#datenschutz h3,
.barrierefreiheit h3 
    margin: 1rem 0