@import "./DguvColors"

$primary: $DGUV-Blau
$primary-light: $Blau-3
$second: $Blau-10
$alternative: $DGUV-Violett
$white: #ffffff
$black: #000000
$gray: $DGUV-Grau
$highlight: #ffffff
$red: $DGUV-Rot

$imgPath: "../images"
$cartoonPath: "../images/cartoons"
$logoPath: "#{$imgPath}/dguv-logo.svg"

$fontMain: Arial, Helvetica, sans-serif
$fontSize: 16px
$fontSizeH1: calc(1.5rem + 0.75vw)
