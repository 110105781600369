@import "../resources/css/Variables"

footer#footer
    background: $primary
    padding: 0.5rem 1rem
    z-index: 4
    @media (min-width: 1400px)
        margin-top: 1px

.footer-links
    display: flex
    flex-direction: row
    justify-content: space-evenly
    align-items: center
    flex-flow: wrap
    padding: 0
    @media (min-width: 1400px)
        justify-content: flex-end

.footer-links.container-lg
    padding: 0

a.footer-link
    color: $white
    text-decoration: none
    display: flex
    flex-direction: column
    justify-content: center
    font-size: calc(0.8rem + 0.3vw)
    // padding: 0 1rem
    transition: 500ms
    &:hover
        color: $second
        transition: 500ms
    @media (max-width: 576px)
        padding: 0 1rem
    @media (max-width: 767px)
        min-height: 48px
    @media (min-width: 1400px)
        margin-right: 1rem

a.footer-link:first-child
    padding-left: 0

a.footer-link:last-child
    padding-right: 0
    @media (min-width: 1400px)
        margin-right: 0