@import "../../resources/css/Variables"

#search .search
    display: flex
    flex-direction: column
    .search-bar-wrapper
        padding: 0
    .search-results-wrapper
        margin-top: 1rem
        margin-bottom: 1rem
        p
            padding: 0
        .search-result-title
            // margin-bottom: 1.5rem
        .search-results-text
            display: flex
            flex-wrap: wrap
            .search-result
                width: 100%
                @media (min-width: 768px)
                    width: calc(50% - 1.5rem)
                    margin-right: 1.5rem
                    &:last-child
                        margin-right: 0
                @media (min-width: 1400px)
                    width: calc(33% - 3rem)
                    margin-right: 1.5rem
                    &:last-child
                        margin-right: 0


.search-bar-wrapper 
    position: relative
    align-items: center
    justify-content: center
    display: flex
    margin-top: 1rem
    padding: 0 1rem

.input-wrapper
    width: calc(100% - 3rem - 1vw)
    align-items: center
    display: flex

input.searchbar,
input.searchbar:active
    -webkit-appearance: none
    width: 100%
    border: 2px solid $primary
    border-radius: 1rem 0 0 1rem
    min-width: 5rem
    color: $primary
    min-height: calc(1.5em + 1rem + 2px)
    padding: 0 1rem
    font-size: 1.25rem
    &:focus-visible
        border-color: $primary-light
        color: $primary-light
        appearance: none
        outline: none
    &:active, &:focus, &:focus-visible, &:focus-within
    border-width: 3px

.search-bar-wrapper .search-icon
    margin: 0

.search-bar-wrapper label
    position: absolute
    left: 2rem
    margin: 0
    color: $primary

.search-bar-wrapper a.btn:focus,
.search-bar-wrapper a.btn:active,
.search-bar-wrapper a.btn:hover
    background-color: $primary-light
    transition: 500ms

.search-bar-wrapper a i
    color: $white

#navbar
    .search-results-wrapper
        display: flex
        flex-direction: column
        height: 100%
        padding: 1rem
        margin-top: 1rem
        overflow-y: auto
        .search-result-title
            margin-bottom: 1.5rem
            padding: 0
        a.search-result-heading
            color: $primary
            text-decoration: underline
            &:hover
                color: $primary-light
        p
            padding: 0

.search-results-heading-wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    margin-bottom: 1rem

a.search-result
    display: flex
    margin-bottom: 0.5rem
    color: $primary

.search-result-text
    color: $gray