@import "../../resources/css/Variables"

.close-btn
    background: none
    border: none
    color: $primary
    font-size: 2rem
    cursor: pointer
    padding: 0.5rem 1rem
    -webkit-filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, .1))
    filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, .1))
    min-width: 48px
    min-height: 48px
    transition: 500ms
    z-index: 16
    &:hover
        color: $primary-light
        transition: 500ms