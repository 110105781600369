#navbar a.dguv-logo 
    display: flex
    justify-content: flex-start
    align-items: center
    width: auto
    height: 100%
    padding: 0
    background-image: url("../../resources/images/dguv-icon.svg")
    background-repeat: no-repeat
    background-position: center center
    @media (min-width: 768px)
        background-image: none 

#navbar a.dguv-logo img
    width: auto
    height: 100%
    min-width: 166px
    min-height: 70px
    display: none
    @media (min-width: 768px)
        display: flex

#navbar .logo-btn-wrapper 
    width: 66%
    display: flex
    align-items: center
    justify-content: flex-start