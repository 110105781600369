@import "../../resources/css/Variables"

.search 
    .search-btn
        display: flex
        justify-content: center
        align-items: center
        width: calc(3rem + 1vw)
        min-height: calc(1.5em + 1rem + 2px)
        background-color: $primary
        border-radius: 1rem
        border-top-left-radius: inherit
        border-bottom-left-radius: inherit
        border: 2px solid $primary
        padding-right: 0.5rem
        font-size: 1.25rem
        transition: 500ms
        svg
            path
                fill: $white
        &:hover
            background-color: $primary-light
            border-color: $primary-light
            transition: 500ms
            svg
                path
                    fill: $white

 
.search-btn
    svg
        path
            fill: $primary
            transition: 500ms
    &:hover
        transition: 500ms
        svg
            path
                fill: $primary-light
                transition: 500ms

