@import "../resources/css/Variables"
    
header
    background: $white
    img 
        background: none

#navbar
    padding: 1rem
    display: flex
    min-height: 5rem
    height: calc(5rem + 2vw)
    max-height: 8rem

#navbar a 
    text-decoration: none

.navbar-wrapper 
    display: flex
    justify-content: space-between
    padding: 0 !important
    height: 100%
    @media (min-width: 992px)
        background: none

.navbar-btn
    display: flex
    min-width: calc(32px + 1rem)
    min-height: calc(32px + 1rem)
    cursor: pointer
    background: none
    border: none
    color: $primary
    font-size: 2rem
    transition: 500ms
    padding: 0.5rem
    svg
        path
            fill: $primary
            transition: 500ms
    &:hover
        color: $primary-light    
        svg
            path
                fill: $primary-light
                transition: 500ms

#navbar .nav-btn-wrapper 
    width: 33%
    display: flex
    align-items: center
    justify-content: flex-end

#navbar .nav-btn-wrapper > .navbar-btn
    margin-right: 2rem
    &:last-child
        margin-right: 0
    @media (max-width: 768px)
        margin-right: 2vw
    @media (max-width: 320px)
        display: none
        &:last-child
            display: unset

#navbar .nav-btn-wrapper > .navbar-btn

// Header Row
#header-row
    background: $primary
    color: $white
    font-family: $fontMain
    padding-top: 0.5rem
    padding-bottom: 0.5rem
    // .container-lg
    //     @media (min-width: 768px)
    //         max-width: 992px

#header-row p
    margin: 0
    padding: 0