@import "./Variables"

html
    font-size: $fontSize

.sr-only
    position: absolute
    width: 1px
    height: 1px
    padding: 0
    margin: -1px
    overflow: hidden
    clip: rect(0,0,0,0)
    border: 0
    color: $primary

.sr-only.sr-only-light
    color: $white

.no-display
    display: none !important

body
    font-family: $fontMain !important
    min-height: 100vh
    display: flex
    flex-direction: column
    margin: 0 !important
    font-size: 1rem !important
    overflow-x: hidden
    word-break: break-word
    h1, h2, h3, h4, h5, h6
        font-size: 100%
    h1, h2
        font-family: $fontMain
        font-weight: bold
        color: $primary
        hyphens: auto
    h1
        font-size: $fontSizeH1

#content-wrapper 
    padding-top: 0
    flex: 1 0 auto

#root, .App 
    display: flex
    flex-direction: column
    flex: 1 0 auto

.content-sec .container-lg, .content-sec .container-fluid, .content-top-wrapper .container-md
    padding-right: var(--bs-gutter-x,1rem)
    padding-left: var(--bs-gutter-x,1rem)
    padding-top: 1rem
    padding-bottom: 0.5rem

.flex-container 
    display: flex
    flex-direction: row
    flex-wrap: wrap

a.btn
    background-color: $primary
    color: $white
    font-size: 1.2rem
    margin: 0.5rem 0
    transition: 500ms
    &:hover
        background-color: $primary-light
        color: $white
        transition: 500ms
    &:active, &:focus, &:focus-visible, &:focus-within
        outline: 2px solid $black
        background-color: $primary-light
        color: $white
        transition: 500ms

// popup copy window
.popup-overlay
    position: absolute
    left: calc( 50% - 125px )
    top: 1rem
    width: 250px
    display: flex
    justify-content: space-between
    align-items: center
    -webkit-filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, .3))
    filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, .6))
    padding: 0.5rem 0.5rem 0.5rem 1rem
    background: $white
    border-radius: 0.5rem
    color: $primary
    z-index: 10
    font-size: 1rem

.popup-overlay i 
    content: url(../images/icons/close_blue.svg)
    margin-left: 0.5rem
    cursor: pointer

.loading-animation
    position: fixed
    width: 100%
    height: 100%
    // background: rgba(255,255,255,0.5)
    z-index: 1000
    .spinner-icon
        animation: spin 2s infinite
        width: 50px
        height: 50px
        position: relative
        top: 10vh
        left: calc( 50% - 25px )
        color: $primary

// speech bubble
.bubble 
    width: auto
    max-width: 350px
    padding: 1rem
    background: $white
    box-shadow: 1px 0px 2px rgba(0, 0, 0, .3)
    z-index: 6
    -webkit-border-radius: 20px
    -moz-border-radius: 20px
    border-radius: 20px
    border: solid 9px $black
    color: $black
    font-family: Arial, Helvetica, sans-serif

.bubble:after 
    content: ''
    position: absolute
    border-style: solid
    border-width: 0 6px 40px
    border-color: $white transparent
    display: block
    width: 0
    z-index: 1
    bottom: -32px
    transform: rotate(208deg)
    left: 13px

.bubble:before 
    content: ''
    position: absolute
    border-style: solid
    border-width: 0 15px 50px
    border-color: $black transparent
    display: block
    width: 0
    z-index: 0
    bottom: -45px
    left: 1px
    transform: rotate(210deg)

p.bubble-text
    margin: 0
    text-align: left

@keyframes spin
    from 
        transform: rotate(0deg)
    to
        transform: rotate(359deg)