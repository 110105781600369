@import "../../resources/css/Variables"

.overlay
    position: fixed
    top: 0
    left: -100% 
    z-index: 10
    width: 100%
    height: 100vh
    background: rgba(255,255,255,0.98)
    overflow-y: auto
    transition: 500ms
    font-size: $fontSize
    @media (min-width: 1400px)
        width: 33%
    @media (min-width: 768px)
        width: 50%
        box-shadow: 1px 0px 10px rgba(0, 0, 0, .15)
    &.active
        display: flex
        left: 0
        transition: 500ms

    .overlay-wrapper
        width:100%
        display: flex
        flex-direction: column

    .menu-background
        height: 45vw
        min-height: 300px
        width: auto
        position: absolute
        right: 10vw
        bottom: 0
        z-index: 15
        overflow: hidden
        pointer-events: none
        @media (min-width: 768px)
            height: 25vw
            right: 3vw

    .menu-background-img
        height: 120%
        width: auto
        opacity: 0.15
        @media (min-width: 768px)
            height: 120%
            width: auto
            opacity: 0.15

    .overlay-nav
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        padding: 0 0.5rem
        .overlay-nav-btn-wrapper
            display: flex
            align-items: center
        a
            color: $primary
            svg
                path
                    fill: $primary
            &:hover
                color: $primary-light
                svg
                    path
                        fill: $primary-light
        .overview-btn
            padding: 0.5rem
    
    .home-btn 
        font-size: 1.775rem

    .chapter
        display: flex
        flex-direction: column

    .menu-links
        margin-top: 1.5vh
        padding: 1rem 1rem 0
        overflow: hidden
        @media (min-width: 768px)
            overflow-y: auto
            
        .subChapterOverview
            position: fixed
            top: 0
            left: -100%
            width: 100%
            transition: 500ms
            background: rgba(255,255,255,0.98)
            z-index: 15
            display: flex
            flex-direction: column
            height: 100%
            padding-top: 1rem
            overflow-y: auto
            @media (min-width: 768px)
                display: none
            &.active
                left: 0%
                transition: 500ms
                padding-left: 2rem
                padding-right: 2rem
                @media (min-width: 768px)
                    position: relative
                    display: flex
                    padding: 0 1rem
                    margin-bottom: 1rem
                    .subChapter-heading
                        display: none

    .menu-links-wrapper
        flex: 1 0 auto
        display: flex
        flex-direction: column
        justify-content: space-between

    .menu-heading
        margin: 2rem 0 1rem 0

    a.menu-link
        display: block
        width: 100%
        text-align: start
        color: $primary
        font-size: calc(1rem + 1vw)
        margin-bottom: 5vh
        hyphens: auto
        @media (min-width: 768px)
            font-size: calc(1rem + 0.35vw)
            margin-bottom: 1rem

    .menu-chapter-heading
        display: flex
        color: $primary
        margin-top: 0
        margin-bottom: .5rem
        padding: 0
        font-weight: 500
        font-size: calc(1.15rem + 1.5vw)
        text-align: left
        font-family: Arial, Helvetica, sans-serif
        background: none
        border: none
        margin-bottom: calc(1.5rem + 1.5vw)
        position: relative
        transition: 500ms
        @media (min-width: 768px)
            margin-bottom: 2rem
            font-size: calc(1.25rem + 0.5vw)
        &:hover
            color: $primary-light
            transition: 500ms
            span
                // &::after
                //     content: url(../../resources/images/icons/arrow-right_lightblue.svg)
                //     transition: 500ms
        span
            display: flex
            width: calc(100% - (1.5rem + 1.5vw))
            @media (min-width: 768px)
                width: 85%
                display: flex
            // &::after
            //     color: $primary
            //     content: url(../../resources/images/icons/arrow-right_blue.svg)
            //     font-weight: 900
            //     right: 0.25rem
            //     position: absolute
            //     top: 0
            //     margin-bottom: calc(1.5rem + 2vw)
            //     transition: 500ms
        &.clicked svg
            @media (min-width: 768px)
                transform: rotate(90deg)

.chapter, .menu-links, .menu-links-wrapper, .overlay-wrapper
    position: relative

.subChapter-heading-wrapper
    display: block
    margin: 1rem 0 3rem 0
    @media (min-width: 768px)
        display: none

.backToOverview
    display: flex
    flex-direction: row
    align-items: center
    max-width: calc(100% - 48px)
    padding: 0
    background: none
    border: none
    font-size: 2rem
    color: $primary
    // padding: 0.5rem 1rem
    margin-left: -1rem
    cursor: pointer
    transition: 500ms
    &:hover
        color: $primary-light
        h2
            color: $primary-light
            transition: 500ms
    h2
        color: $primary
        font-size: 1.5rem
        transition: 500ms
        margin-bottom: 0
        text-align: left
        hyphens: none
    @media (min-width: 768px)
        display: none
        i
            background-image: url(../../resources/images/icons/arrow-left_blue.svg)

.menu-bottom-links 
    margin: 1vh 0
    display: flex
    flex-direction: column
    align-items: center
    font-family: Arial, Helvetica, sans-serif
    font-size: calc(0.8rem + 0.3vw)

.menu-bottom-links a.menu-bottom-link 
    color: $primary
    margin-bottom: 0.3rem

.menu-bottom-links a.menu-bottom-link:hover 
    color: $primary-light